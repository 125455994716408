import React from "react";
import handleViewport from "react-in-viewport";

class Baffle_Text extends React.Component {
    render() {
        return <h1 className="baffle_text">{this.text()}</h1>;
    }

    text() {
        const { text } = this.props;

        return <h1>{text}</h1>;
    }
}
const BaffleText = handleViewport(Baffle_Text);

export default BaffleText;
