import React from "react";
import "../../scss/retro.scss";
function Footer() {
    return (
        <footer
            className="page-footer"
            style={{
                textAlign: "center",
                height: "4rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#222",
                color: "#fff",
                maxHeight: "40px",
            }}
        >
            <p style={{ marginBottom: 0 }}>
                &copy; {new Date().getFullYear()} &nbsp;
                <span style={{ color: "#83acf8" }}>Kolton Webber</span>. &nbsp;
                Built with{" "}
                <a style={{ color: "#83acf8" }} href="https:www.gatsbyjs.com">
                    Gatsby
                </a>
                .
            </p>
        </footer>
    );
}

export default Footer;
