import React from "react";
import "./styles.scss";
import { StaticQuery, graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Glitch from "components/glitch";
import Typewriter from "typewriter-effect";
import ThemeContext from "../../context";

class Hero extends React.Component {
    static contextType = ThemeContext;

    render() {
        // Dealing with Gatsby "window.innerWidth" - window is not defined error
        if (typeof window === `undefined`) {
            return <></>;
        }
        return (
            <section
                id={`${this.props.id}`}
                className="hero"
                style={{ height: this.context.height }}
            >
                <Row>
                    <Col lg={12} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4 as="h1">Hello, I'm</h4>
                            </div>
                            <Glitch text="Kolton Webber" />
                            <Typewriter
                                options={{
                                    strings: [
                                        "Full Stack Builder",
                                        "Angular Adapter",
                                        "Microservice Manager",
                                        "React Enthusiast",
                                        "Database Doer",
                                        "Mobile App Maker",
                                    ],
                                    autoStart: true,
                                    pauseFor: 3000,
                                    loop: true,
                                    delay: 100,

                                    deleteSpeed: 100,
                                }}
                            />
                        </div>
                        <div className="bottom-div">
                            <div className="profile-pic">
                                {/* <span className="inner-profile-pic"></span> */}
                                <div
                                    className="inner-profile-pic"
                                    style={{
                                        width: "160px",
                                        height: "190px",
                                    }}
                                />
                                <img
                                    src={
                                        this.props.mainImg.childImageSharp.fluid
                                            .src
                                    }
                                    style={{
                                        width: "160px",
                                        height: "190px",
                                        zIndex: "100",
                                        boxShadow:
                                            "rgba(131, 172, 248, 0.73) 2px 4px 6px -2px, rgba(131, 172, 248, 0.73) 2px 2px 6px -2px, rgba(131, 172, 248, 0.73) -4px -2px 6px -2px, rgba(131, 172, 248, 0.73) 2px -4px 6px -2px",
                                    }}
                                    alt="person"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignContent: "center",
                                    alignItems: "center",
                                    margin: "10px",
                                    padding: "0px",
                                }}
                            >
                                <div
                                    className="hover-button"
                                    style={{ background: "#251170" }}
                                >
                                    <span />
                                    <div
                                        style={{
                                            width: "158px",
                                            height: "54px",
                                            background: "#222",
                                            zIndex: "100",
                                            boxShadow:
                                                "rgba(131, 172, 248, 0.73) 2px 4px 6px -2px, rgba(131, 172, 248, 0.73) 2px 2px 6px -2px, rgba(131, 172, 248, 0.73) -4px -2px 6px -2px, rgba(131, 172, 248, 0.73) 2px -4px 6px -2px",
                                        }}
                                    >
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={"/KoltonWebberResume2023.pdf"}
                                            style={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                        >
                                            Resume
                                        </a>
                                    </div>
                                </div>
                                <div
                                    className="hover-button"
                                    style={{ background: "#251170" }}
                                >
                                    <span />
                                    <div
                                        style={{
                                            width: "158px",
                                            height: "54px",
                                            background: "#222",
                                            zIndex: "100",
                                            boxShadow:
                                                "rgba(131, 172, 248, 0.73) 2px 4px 6px -2px, rgba(131, 172, 248, 0.73) 2px 2px 6px -2px, rgba(131, 172, 248, 0.73) -4px -2px 6px -2px, rgba(131, 172, 248, 0.73) 2px -4px 6px -2px",
                                        }}
                                    >
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={"https://github.com/kawgh1"}
                                            style={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                        >
                                            Github
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                Img: file(relativePath: { eq: "self2.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            src
                        }
                    }
                }
            }
        `}
        render={({ icons, Img }) => (
            <Hero icons={icons} mainImg={Img} {...props} />
        )}
    />
);
