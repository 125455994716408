import React from "react";
import Particles from "react-particles-js";
import { StaticQuery, graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../context";
// code logo fade in
import AnimationContainer from "components/animation-container";
import "./styles.scss";

class About extends React.Component {
    static contextType = ThemeContext;

    render() {
        // Dealing with Gatsby "window.innerWidth" - window is not defined error
        if (typeof window === `undefined`) {
            return <></>;
        }
        return (
            <section
                id={`${this.props.id}`}
                className="about"
                style={{ height: this.context.height }}
            >
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h1>I'm a full stack web developer</h1>
                            <h1>with a background in financial services.</h1>
                            <div
                                className="separator"
                                style={{ marginBottom: "40px" }}
                            />
                            <p>
                                I completed a B.S. degree in Computer Science in
                                2020. I currently work at a tech startup and
                                occasionally build websites for clients. I enjoy
                                creating robust, clean and intuitive user
                                experiences and working as part of a team.
                            </p>
                            <p>
                                I believe matching tools to the job and good
                                planning go a long way, but as challenges occur
                                adaptability is key. There is always another way
                                to solve a problem.
                            </p>

                            <div className="social social_icons">
                                <FontAwesomeIcon
                                    icon={faGithub}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open(
                                            "https://www.github.com/kawgh1"
                                        )
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faLinkedin}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open(
                                            "https://www.linkedin.com/in/kolton-w-4363b74a/"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                        <div
                            className="line-text"
                            style={{ marginBottom: "20px" }}
                        >
                            <h5>Technologies & Skills</h5>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: "100%",
                                flexWrap: "wrap",
                            }}
                        >
                            {this.codelogos()}
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    particles: {
                        number: {
                            value: 30,
                            density: {
                                enable: true,
                                value_area: 500,
                            },
                        },
                        color: {
                            value: "#fff",
                        },
                        shape: {
                            type: "circle",
                            stroke: {
                                width: 0,
                                color: "#000000",
                            },
                            polygon: {
                                nb_sides: 5,
                            },
                        },
                        opacity: {
                            value: 0.9,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.01,
                                sync: false,
                            },
                        },
                        size: {
                            value: 5,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 40,
                                size_min: 0.1,
                                sync: false,
                            },
                        },
                        line_linked: {
                            enable: false,
                            distance: 500,
                            color: "#ffffff",
                            opacity: 0.4,
                            width: 2,
                        },
                        move: {
                            enable: true,
                            speed: 2,
                            direction: "bottom",
                            random: false,
                            straight: false,
                            out_mode: "out",
                            bounce: false,
                            attract: {
                                enable: false,
                                rotateX: 1200,
                                rotateY: 1200,
                            },
                        },
                    },
                    retina_detect: true,
                }}
            />
        );
    }

    codelogos() {
        return this.props.codelogos.edges.map((value, index) => {
            return (
                <AnimationContainer
                    delay={index * 50}
                    animation="fadeIn slow"
                    key={index}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={value.node.childImageSharp.fluid.src}
                            key={index}
                            alt={value.node.childImageSharp.fluid.originalName.slice(
                                0,
                                -4
                            )}
                            style={{
                                color: "white",
                                margin: "10px",
                                marginBottom: "5px",
                                borderRadius: "2px",
                            }}
                        />
                        <p style={{ color: "white", fontSize: "12px" }}>
                            {value.node.childImageSharp.fluid.originalName.slice(
                                0,
                                -4
                            )}
                        </p>
                    </div>
                </AnimationContainer>
            );
        });
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                codelogos: allFile(
                    filter: {
                        extension: { regex: "/(png)/" }
                        relativeDirectory: { eq: "codelogos" }
                    }
                    sort: {
                        fields: [childImageSharp___resize___originalName]
                        order: ASC
                    }
                ) {
                    edges {
                        node {
                            childImageSharp {
                                fluid(maxWidth: 55, maxHeight: 55) {
                                    src
                                    originalName
                                }
                                resize(height: 100, width: 100) {
                                    src
                                    width
                                    height
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ codelogos }) => <About codelogos={codelogos} {...props} />}
    />
);
