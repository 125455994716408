import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import BaffleText from "components/baffle-text";
import ThemeContext from "../../context";
// React Slick Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        const { items } = this.props;
        this.state = {
            category: null,
            col:
                items.length > 6
                    ? 4
                    : items.length > 4
                    ? 3
                    : items.length > 3
                    ? 2
                    : items.length > 1
                    ? 2
                    : 1,
            items: this.props.items,
            showPortfolio: true,
        };
    }
    static contextType = ThemeContext;

    render() {
        // Dealing with Gatsby "window.innerWidth" - window is not defined error
        if (typeof window === `undefined`) {
            return <></>;
        }
        const settings = {
            dots: true,
            infinite: true,
            speed: 2500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 7500,
        };

        return (
            <section
                id={`${this.props.id}`}
                className="portfolio"
                style={{ height: this.context.height }}
            >
                <Row>
                    <Col md={2} className="side">
                        <h1>
                            <BaffleText text="Portfolio" />
                        </h1>
                    </Col>
                    <Col
                        md={`${window.innerWidth < 992 ? 4 : 10}`}
                        className="recent-works"
                    >
                        <div className="portfolio_selector">
                            <button
                                className="portfolio_category"
                                onClick={() => this.changeCategory(null)}
                            >
                                <span
                                    className={`${
                                        !this.state.category ? "active" : ""
                                    }`}
                                >
                                    All
                                </span>
                            </button>
                            {this.categories()}
                        </div>

                        <div className="content">
                            {window.innerWidth < 992 ? (
                                <div
                                    className="portfolio_container"
                                    style={{
                                        maxHeight:
                                            this.context.height !== "auto"
                                                ? this.context.height * 0.8
                                                : "inherit",
                                        justifyContent: `${
                                            this.context.height === "auto"
                                                ? "center"
                                                : "flex-start"
                                        }`,
                                        alignItems: "center",
                                    }}
                                >
                                    {this.items()}
                                </div>
                            ) : this.state.category === null ? (
                                <Slider
                                    {...settings}
                                    style={{
                                        maxHeight:
                                            this.context.height !== "auto"
                                                ? this.context.height * 0.8
                                                : "inherit",
                                        marginTop: "20px",
                                        padding: "30px",
                                    }}
                                >
                                    {this.items()}
                                </Slider>
                            ) : (
                                <div
                                    className="portfolio_container"
                                    style={{
                                        maxHeight:
                                            this.context.height !== "auto"
                                                ? this.context.height * 0.8
                                                : "inherit",
                                        justifyContent: `${
                                            this.context.height === "auto"
                                                ? "center"
                                                : "flex-start"
                                        }`,
                                    }}
                                >
                                    {this.items()}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }

    items() {
        if (this.state.showPortfolio || this.context.height === "auto") {
            const { items } = this.state;
            return items.map((value, index) => {
                if (
                    value.content.frontmatter.category ===
                        this.state.category ||
                    !this.state.category
                ) {
                    if (value.content.frontmatter.image) {
                        return (
                            <div
                                className="portfolio_item"
                                style={{
                                    width:
                                        this.context.height === "auto"
                                            ? "100%"
                                            : this.state.col === 4
                                            ? "25%"
                                            : this.state.col === 3
                                            ? "25%"
                                            : this.state.col === 2
                                            ? "25%"
                                            : "25%",

                                    minWidth:
                                        window.innerWidth < 600
                                            ? "100%"
                                            : "300px",
                                    alignItems:
                                        window.innerWidth < 992
                                            ? "center"
                                            : "flex-start",
                                }}
                                key={index}
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={value.content.frontmatter.livesite}
                                    style={{ textDecoration: "none" }}
                                >
                                    <img
                                        src={
                                            value.content.frontmatter.image
                                                .childImageSharp.fluid.src
                                        }
                                        alt={value.content.frontmatter.title}
                                        style={{
                                            borderRadius: "10px",
                                            marginLeft: "4px",
                                            maxWidth:
                                                window.innerWidth < 992
                                                    ? 300
                                                    : window.innerWidth > 1400
                                                    ? 300
                                                    : 200,

                                            boxShadow:
                                                "rgba(131, 172, 248, 0.8) 2px 4px 6px -2px, rgba(131, 172, 248, 0.93) 3px 3px 6px -3px, rgba(131, 172, 248, 0.95) 2px -2px 6px -2px, rgba(131, 172, 248, 0.93) 2px -3px 8px -3px",
                                        }}
                                    />
                                </a>

                                {value.content.frontmatter.features ? (
                                    <div
                                        className="portfolio_item"
                                        style={{
                                            minHeight:
                                                window.innerWidth < 992
                                                    ? "270px"
                                                    : window.innerWidth > 1400
                                                    ? "270px"
                                                    : "290px",
                                            minWidth:
                                                window.innerWidth < 600
                                                    ? "100%"
                                                    : "250px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: "40px",
                                                minWidth: "250px",
                                            }}
                                        >
                                            <h3
                                                className="portfolio_item-title"
                                                style={{
                                                    color: "white",
                                                    margin: "25px 0px",
                                                    alignSelf: "flex-start",
                                                }}
                                            >
                                                {
                                                    value.content.frontmatter
                                                        .title
                                                }
                                            </h3>
                                            {value.content.frontmatter.features.map(
                                                (item, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            style={{
                                                                color: "white",
                                                                marginLeft:
                                                                    "15px",
                                                                maxWidth:
                                                                    "200px",
                                                                marginBottom:
                                                                    "1px",
                                                            }}
                                                        >
                                                            {item}
                                                        </li>
                                                    );
                                                }
                                            )}
                                            <div
                                                className="github-and-site-link-div"
                                                style={{
                                                    position: "absolute",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    maxWidth: "200px",
                                                    bottom: 0,
                                                    height: "40px",
                                                }}
                                            >
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="cta-btn cta-btn--hero"
                                                    style={{
                                                        color: "rgb(131, 172, 248)",
                                                        fontSize: "18px",
                                                        fontWeight: 600,
                                                        marginRight: "20px",
                                                    }}
                                                    href={
                                                        value.content
                                                            .frontmatter
                                                            .livesite
                                                    }
                                                >
                                                    Live
                                                </a>
                                                {/* NTX BOOKS code is private */}
                                                {value.content.frontmatter
                                                    .title ===
                                                "North Texas Bookkeeping, LLC" ? (
                                                    <></>
                                                ) : (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="cta-btn cta-btn--code"
                                                        style={{
                                                            color: "white",
                                                            fontSize: "18px",
                                                            fontWeight: 600,
                                                        }}
                                                        href={
                                                            value.content
                                                                .frontmatter
                                                                .github
                                                        }
                                                    >
                                                        Code
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    }
                }
                return false;
            });
        }
    }

    getItemCount(category) {
        let total = 0;
        this.state.items.forEach((v) => {
            if (v.content.frontmatter.category === category || !category)
                total++;
        });
        return total;
    }

    changeCategory(category) {
        const { items } = this.props;
        this.setState({ items: [] });
        let total = 0;
        items.forEach((v) => {
            if (v.content.frontmatter.category === category || !category)
                total++;
        });
        let col =
            total > 6 ? 4 : total > 4 ? 3 : total > 3 ? 2 : total > 1 ? 2 : 1;

        this.setState({ category: category, col: col });
        // no delay when click change category
        // setTimeout(() => {
        //     this.setState({ items: items });
        // }, 50);
        this.setState({ items: items });
    }

    categories() {
        const { items } = this.props;
        let categories = [];
        for (var v of items) {
            categories.push(v.content.frontmatter.category);
        }
        categories = [...new Set(categories)];
        return categories.map((value, index) => {
            return (
                <button
                    className="portfolio_category"
                    onClick={() => this.changeCategory(value)}
                    key={index}
                >
                    <span
                        className={`${
                            this.state.category === value ? "active" : ""
                        }`}
                    >
                        {value}
                    </span>
                </button>
            );
        });
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                items: allMarkdownRemark(
                    filter: {
                        fileAbsolutePath: { regex: "/(content)/(portfolio)/" }
                    }
                    sort: { fields: [frontmatter___id], order: ASC }
                    # The layout is built for 6 portfolio items #
                    limit: 9
                ) {
                    edges {
                        content: node {
                            html
                            frontmatter {
                                id
                                title
                                category
                                features
                                livesite
                                github
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 700, maxHeight: 700) {
                                            src
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ items }) => <Portfolio items={items.edges} {...props} />}
    />
);
