import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import BaffleText from "components/baffle-text";
import AnimationContainer from "components/animation-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import ThemeContext from "../../context";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";

class Reviews extends React.Component {
    static contextType = ThemeContext;

    render() {
        return (
            <section
                id={`${this.props.id}`}
                className="testimonials"
                style={{ height: this.context.height }}
            >
                <Row
                    className="top"
                    style={{
                        maxHeight:
                            this.context.height !== "auto"
                                ? this.context.height * 0.8
                                : "inherit",
                    }}
                >
                    <div className="content">
                        <Col md={12}>
                            <div className="line-text">
                                <h4>&nbsp;</h4>
                            </div>
                            <div className="heading">
                                <BaffleText text="Reviews" />
                            </div>
                            <div
                                className="testimonials_container"
                                style={{
                                    minHeight:
                                        this.context.height !== "auto"
                                            ? this.context.height * 0.6
                                            : "auto",
                                }}
                            >
                                <Container>
                                    {this.quote()}
                                    {this.testimonial_slider()}
                                </Container>
                            </div>
                        </Col>
                    </div>
                </Row>
            </section>
        );
    }

    testimonial_slider() {
        const settings = {
            dots: true,
            swipe: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 10000,
            loop: true,
        };
        return (
            <AnimationContainer delay={100} animation="fadeIn slow">
                <Slider {...settings}>{this.testimonial_items()}</Slider>
            </AnimationContainer>
        );
    }
    testimonial_items() {
        return this.props.testimonials.edges.map((value, index) => {
            return (
                <div className="testimonial" key={index}>
                    <h2>{value.content.frontmatter.heading}</h2>
                    <div className="testimonial_content" />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <p style={{ color: "white", maxWidth: "400px" }}>
                            {value.content.frontmatter.quote}
                        </p>
                    </div>

                    <div className="client_container">
                        <div className="client">
                            <img
                                src={
                                    value.content.frontmatter.image
                                        .childImageSharp.fluid.src
                                }
                                alt={value.content.frontmatter.name}
                            />
                            <div className="info">
                                <p className="name">
                                    {value.content.frontmatter.name}
                                </p>
                                <p className="profession">
                                    {value.content.frontmatter.profession}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    quote() {
        return (
            <div className="quote">
                <AnimationContainer delay={0} animation="fadeIn slow">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                </AnimationContainer>
            </div>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                clients: allFile(
                    filter: {
                        extension: { regex: "/(png)/" }
                        relativeDirectory: { eq: "clients" }
                    }
                ) {
                    edges {
                        node {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    src
                                }
                            }
                        }
                    }
                }
                testimonials: allMarkdownRemark(
                    filter: { fileAbsolutePath: { regex: "/(testimonials)/" } }
                    sort: { fields: [frontmatter___id], order: ASC }
                    limit: 8
                ) {
                    edges {
                        content: node {
                            html
                            frontmatter {
                                id
                                name
                                profession
                                heading
                                quote
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 200, maxHeight: 200) {
                                            src
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ clients, testimonials }) => (
            <Reviews clients={clients} testimonials={testimonials} {...props} />
        )}
    />
);
