import React from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import BaffleText from "components/baffle-text";
import ThemeContext from "../../context";
import { StaticQuery, graphql } from "gatsby";
// lottie
import Lottie from "react-lottie";
import animationData from "../../../content/lottie/lotttie-code-animation.json";
// footer
import Footer from "components/footer";

class Contact extends React.Component {
    static contextType = ThemeContext;

    render() {
        // Dealing with Gatsby "window.innerWidth" - window is not defined error
        if (typeof window === `undefined`) {
            return <></>;
        }
        return (
            <section
                id={`${this.props.id}`}
                className="contact"
                style={{ height: this.context.height }}
            >
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" />
                        </h2>
                    </Col>

                    <Col md={5} className="form">
                        <div
                            className="line-text"
                            style={{
                                marginBottom: "0px",
                                marginLeft: "50px",
                                marginTop: "40px",
                            }}
                        >
                            <h4>Get In Touch!</h4>
                        </div>
                        <div
                            className="form-container"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div className="lottie">
                                <Lottie
                                    style={{ cursor: "default" }}
                                    options={defaultOptions}
                                    height={280}
                                    width={300}
                                />
                            </div>
                            <div
                                className="hover-button"
                                style={{ margin: "20px" }}
                            >
                                <span />
                                <div
                                    style={{
                                        width: "158px",
                                        height: "54px",
                                        background: "#222",
                                        zIndex: "100",
                                        boxShadow:
                                            "rgba(131, 172, 248, 0.73) 2px 4px 6px -2px, rgba(131, 172, 248, 0.73) 2px 2px 6px -2px, rgba(131, 172, 248, 0.73) -4px -2px 6px -2px, rgba(131, 172, 248, 0.73) 2px -4px 6px -2px",
                                    }}
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/KoltonWebberResume2023.pdf"}
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                        }}
                                    >
                                        Resume
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={5} className="form">
                        <div className="form-container">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop:
                                        window.innerWidth < 992
                                            ? "0px"
                                            : "160px",
                                }}
                            >
                                <img
                                    src={
                                        this.props.mainImg.childImageSharp.fluid
                                            .src
                                    }
                                    style={{
                                        width: "170px",
                                        height: "205px",
                                        zIndex: "100",
                                        borderRadius: "3px",
                                        boxShadow:
                                            "rgba(131, 172, 248, 0.73) 2px 4px 6px -2px, rgba(131, 172, 248, 0.73) 2px 2px 6px -2px, rgba(131, 172, 248, 0.73) -4px -2px 6px -2px, rgba(131, 172, 248, 0.73) 2px -4px 6px -2px",
                                    }}
                                    alt="person"
                                />
                                <div
                                    className="hover-button"
                                    style={{ margin: "40px" }}
                                >
                                    <span />
                                    <div
                                        style={{
                                            width: "158px",
                                            height: "54px",
                                            background: "#222",
                                            zIndex: "100",
                                            boxShadow:
                                                "rgba(131, 172, 248, 0.73) 2px 4px 6px -2px, rgba(131, 172, 248, 0.73) 2px 2px 6px -2px, rgba(131, 172, 248, 0.73) -4px -2px 6px -2px, rgba(131, 172, 248, 0.73) 2px -4px 6px -2px",
                                        }}
                                    >
                                        <a
                                            style={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                            target="_blank"
                                            rel="noreferrer"
                                            href="mailto:krseven@gmail.com"
                                        >
                                            Email me
                                        </a>
                                    </div>
                                </div>

                                {window.innerWidth > 991 ? (
                                    <></>
                                ) : (
                                    <div style={{ height: "20px" }}></div>
                                )}
                            </div>
                        </div>
                    </Col>
                    {window.innerWidth > 991 ? (
                        <Col md={3} className="side">
                            <h2>
                                <BaffleText text="" />
                            </h2>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
                <Row
                    style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#222",
                        color: "#fff",
                        height: "5%",
                    }}
                >
                    <Footer />
                </Row>
            </section>
        );
    }
}

// lottie animation
export const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
};

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                Img: file(relativePath: { eq: "selfie.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            src
                        }
                    }
                }
            }
        `}
        render={({ Img }) => <Contact mainImg={Img} {...props} />}
    />
);
